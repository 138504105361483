// 
// user.scss
// Use this to write your custom SCSS
//

.object-fit-cover {
    object-fit: cover;
}

.l20 {
    padding-left: calc((100vw - 1320px) / 2);
}

@media screen and (max-width:1320px) {
    .l20 {
        padding-left: 2.5%;
    }
}

@media screen and (max-width:768px) {
    .l20 {
        padding-left: calc(var(--si-gutter-x)*.5) !important;
        padding-right: calc(var(--si-gutter-x)*.5) !important;
    }

}

@media screen and (max-width:768px) {

    .u24swiper-top__slide {
        aspect-ratio: 1 / 1
    }


}

@media screen and (max-width:992px) {

    .u24swiper-top__slide {
        aspect-ratio: 1 / 1
    }


}

.bg-gradient-cta {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1.0) 0% ,  rgba(255, 255, 255, 0.3) 20%, rgba(0, 0, 0, 0.2)  30%, rgba(0, 0, 0, 0.4) 70%, #0b0f19 100%) !important;
}
.bg-gradient-finisher{
    background: linear-gradient(180deg, rgb(11, 15, 25) 0% ,  #0a0a0a  100%) !important;

}

.zoomable {
    transition: transform 0.3s;
}
.zoomable__container:hover .zoomable{
    transform:scale(1.04);
   
}
.cursor-pointer{
    cursor: pointer;
}


/*Remember to fix later*/
.glightbox img{
    object-fit: cover;
}